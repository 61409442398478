.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	overflow-y: scroll;
}

.flex-vert {
	flex-direction: column;
}

.center-all {
	justify-content: center;
	align-items: center;
}

.flex-row {
	flex-direction: row;
}

.items-center {
	align-items: center;
}
