/* Global Styles  */

* {
	box-sizing: border-box;
	user-select: none;
	-webkit-user-drag: none;
	-moz-user-select: none;
	outline: none;
}

body {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	height: 100vh;
	width: 100vw;
	position: relative;
	display: flex;
	user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	font-family: "Roboto";
	scroll-behavior: smooth;
}

img {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

h1,
h2 {
	font-family: "Montserrat";
	color: orange;
}

h2 {
	font-size: 24px;
}

p {
	font-size: 19px;
}

a {
	text-decoration: none;
}

section {
	width: 100vw;
	height: 100vh;
	display: flex;
}

::-webkit-scrollbar {
	position: absolute;
	width: 7px;
	background-color: rgb(196, 16, 16);
	height: 0px;
	border: none;
	outline: none;
	box-shadow: none;
}

::-webkit-scrollbar-button {
	display: none;
	border: none;
	outline: none;
	box-shadow: none;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(250, 199, 103);
	width: 7px;
	border: none;
	box-shadow: none;
	outline: none;
}

.loading {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 800px) {
	h1 {
		font-size: 24px;
	}

	p {
		font-size: 17px;
	}
}

/* Font Files -------------- */

/* Roboto */
@font-face {
	font-family: "Roboto";
	src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src: url("./assets/fonts/Roboto/Roboto-Thin.ttf");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

/* Montserrat */

@font-face {
	font-family: "Montserrat";
	src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat";
	src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
