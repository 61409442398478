.online-donation {
	background-color: #ffebd5;
	width: 100%;
	height: 100%;
}

.online-donation-form p {
	font-size: 14px;
	text-align: justify;
}

.online-donation-root {
	width: 100%;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: #ffebd5;
	align-items: center;
	padding-bottom: 20px;
}

.online-head {
	width: 100%;
	text-align: center;
}

.online-donation-form {
	width: 600px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: white;
	padding: 30px;
}

.online-donation-text-input-group {
	width: 100%;
}

.online-donation-btn-grp {
	display: flex;
	justify-content: space-between;
}

.online-donation-big-p p {
	font-size: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.success {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.success-img {
	width: 100%;
	display: flex;
	justify-content: center;
}

.success-img img {
	width: 50%;
}

.success-box {
	width: 700px;
	min-width: 500px;
	background-color: white;
	padding: 20px;
	border-radius: 10px;
}

.success-box p {
	font-size: 14px;
}

.success-btns {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 10px;
}

.success-btns a {
	color: #e67c0b;
	text-align: center;
	font-weight: bold;
	margin-top: 10px;
	border: 1px solid orange;
	padding: 10px;
	border-radius: 5px;
}
