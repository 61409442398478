.nav-bar {
	width: 100%;
	min-height: 80px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	position: relative;
	z-index: 1000;
	background: transparent;
}

.nav-top {
	background: linear-gradient(106.57deg, #e3651d 0%, #fbb68f 100%);
}

.nav-items {
	min-width: 20vw;
	align-items: center;
	flex-wrap: wrap;
	height: max-content;
	display: flex;
	justify-content: space-around;
	gap: 40px;
	list-style: none;
	padding-right: 20px;
	color: white;
	font-size: 18px;

	transition: all 0.3s ease-in-out;
}

.nav-items a {
	color: white;
	text-decoration: none;
}

.nav-items li {
	cursor: pointer;
	padding: 10px;
	transition: all 0.2s ease-in-out;
	position: relative;
}

.nav-items li::after {
	content: "";
	position: absolute;
	bottom: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
}

.nav-items li:hover::after {
	height: 2px;
	background-color: #FBF08F;

	animation: nav_hover 0.3s;
	animation-iteration-count: 1;
}

.nav-items li:not(:hover)::after {
	right: 0px;
	left: auto;

	height: 2px;

	animation-delay: 3s;
	animation: nav_hover_leave 0.5s;
	animation-iteration-count: 1;
}

@keyframes nav_hover {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@keyframes nav_hover_leave {
	0% {
		width: 100%;
		background-color: #FBF08F;
	}
	99% {
		width: 0%;
	}
	100% {
		background-color: none;
	}
}

.nav-active {
	background-color: #ffc800;
	border-radius: 5px 5px 0px 0px;
}

/* @media only screen and (max-width: 700px) {
	.nav-bar {
		display: none;
	}
}
 */
