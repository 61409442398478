/* CSS for Login Page */
.login-header {
	width: 100%;
	height: 350px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding-top: 20px;
	line-height: 10px;
}

.login-header-text {
	line-height: 15px;
	text-align: center;
}

.login-header img {
	width: 50%;
	object-fit: contain;
}

.greyback {
	background-color: #e9e9e9;
}

.paddings {
	padding: 20px;
}

.login-form {
	width: 65%;
	padding: 40px 0px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	box-shadow: 0px 0px 10px #dbdbdb;
}

.login-form-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

/* Admin page styles */
.admin-banner {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.admin-banner img {
	width: 50%;
	object-fit: contain;
}

.admin-header h1 {
	font-size: 30px;
}

.admin-hero {
	width: 100%;
	padding-top: 10px;
	min-height: 60%;
	height: max-content;
	display: flex;
	justify-content: space-between;
}

.admin-hero h2 {
	font-size: 25px;
}

.admin-left {
	width: 20%;
	height: max-content;
}

.admin-left,
.admin-right {
	background-color: white;
	border-radius: 12px;
	box-shadow: 0px 0px 15px #e0e0e0;
	width: 49%;
	height: 100%;
	padding: 0px 15px;
}

.admin-right,
.admin-left {
	overflow-y: auto;
}

.admin-left-head,
.admin-right-head,
.admin-btns-head {
	width: 100%;
	display: flex;
	border-bottom: 1px solid #dbdbdb;
	align-items: center;
	justify-content: space-between;
}

.admin-btns-head {
	justify-content: flex-start;
	gap: 20px;
}

.admin-left-title,
.admin-right-title {
	display: flex;
	gap: 20px;
	align-items: center;
	height: 100%;
}

.admin-left-contents {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	gap: 30px;
	align-items: center;
}

.admin-right-head {
	background-color: white;
	z-index: 100;
	margin-bottom: 20px;
	position: sticky;
	top: 0px;
}

.admin-btns {
	width: 100%;
	height: max-content;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 10px;
	border-radius: 10px;
}

.admin-btns-head {
	border: none;
}

.admin-btns-contents {
	width: 100%;
	height: max-content;
	display: flex;
	padding-top: 15px;
	gap: 10px;
	flex-wrap: wrap;
}

/* Card styling */
.card {
	width: 100%;
	padding: 8px;
	color: rgb(119, 58, 0);
	margin-bottom: 10px;
	background-color: rgb(255, 207, 118);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
}

.card-text {
	line-height: 1.3rem;
	width: 85%;
}

.card-text p {
	font-size: 15px;
}

/* Vps Card */

.vps-container {
	width: 100%;
	height: 100%;
	text-align: center;
}

.vps-card {
	width: 100%;
	display: flex;
	padding: 5px;
	border-bottom: 1px solid orange;
	justify-content: space-between;
	align-items: center;
}

.vps-card-disabled {
	color: rgb(182, 182, 182);
	text-decoration: line-through;
}

.vps-card-left {
	display: flex;
	width: 85%;
	flex-direction: column;
	line-height: 1rem;
	text-align: left;
}

.vps-card-left p {
	font-size: 17px;
}

#vps-created-on {
	font-size: 14px;
	color: rgb(161, 161, 161);
}

/* Edit website styling */
.edit-web-head {
	display: flex;
	width: 97vw;
	line-height: 10px;
	justify-content: space-between;
	margin-bottom: 20px;
}

.edit-web-hero {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.gallery-edit {
	width: 100%;
	display: flex;
	gap: 20px;
	flex-direction: column;
}

.gallery-edit-title {
	display: flex;
	align-items: center;
	width: max-content;
	justify-content: center;
	gap: 30px;
}

.edit-gallery-content {
	width: 100%;
	display: flex;
}

.gallery-images {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.gallery-img {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.gallery-img img {
	width: 230px;
	height: 130px;
	object-fit: contain;
}

.gallery-img p {
	text-align: center;
	font-size: 14px;
}

/* Media queries */
@media only screen and (max-width: 800px) {
	.login-form {
		width: 100%;
	}
	.login-header img {
		width: 100%;
		object-fit: contain;
	}

	.admin-banner img {
		width: 100%;
	}

	.admin-hero {
		flex-wrap: wrap;
		gap: 20px;
	}

	.admin-left,
	.admin-right {
		width: 100%;
		height: fit-content;
	}

	.admin-btns-contents {
		flex-wrap: wrap;
		gap: 30px;
	}
}
