.activities {
	min-width: 100%;
	min-height: 100vh;
	height: max-content;
	position: relative;
	display: flex;
}

.act-blob {
	position: absolute;
	z-index: 10;
	top: 0px;
	right: 0px;
	width: 100%;
	height: 30%;
	background: url("../../assets/images/activities_blob.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.act-hero {
	width: 100%;
	height: 100%;
	display: flex;
	background-color: #fdf6b3;
}

.act-left {
	width: 55%;
	background-color: #fdf6b3;
	padding: 0px 30px;
	z-index: 100;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.act-hero-head {
	width: 100%;
	height: 100px;
	display: flex;
	border-bottom: 2px solid #dadada;
	align-items: center;
	gap: 20px;
}

.act-hero-head img {
	width: 60px;
	height: 60px;
}

.act-hero-text {
	text-align: justify;
}

.act-hero-text p {
	font-size: 18px;
}

.act-right {
	width: 45%;
	height: 100%;
	display: flex;
	margin-top: 50px;
	justify-content: center;
	z-index: 100;
}

.act-right img {
	width: 40%;
	height: 50%;
	object-fit: contain;
}

@media only screen and (max-width: 800px) {
	.act-blob {
		display: none;
	}

	.act-hero {
		flex-direction: column;
	}

	.act-left {
		width: 100%;
		height: 55%;
	}

	.act-right {
		width: 100%;
		height: 45%;
	}

	.act-hero-head img {
		width: 45px;
		height: 45px;
	}

	.act-hero-text p {
		font-size: 14px;
	}

	.act-right img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
