.home {
	min-width: 100%;
	min-height: max-content;
	position: relative;
	background: linear-gradient(106.57deg, #e3651d 0%, #fbb68f 100%);
	display: flex;
	flex-direction: column;
}

.home-hero {
	width: 100%;
	height: 65vh;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.home-left {
	width: 60vw;
	height: 100%;

	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-left: 50px;
	z-index: 100;
}

.home-banner {
	width: 100vw;
	background: linear-gradient(106.57deg, #e3651d 0%, #fbb68f 100%);
	display: flex;
	align-items: center;

	z-index: 10000;
	justify-content: center;
	flex-wrap: wrap;
}

.home-banner img {
	object-fit: contain;
}

.home-hero-text,
.home-hero-text h1 {
	color: white;
	line-height: 35px;
	font-size: 32px;
}

.home-right {
	width: 40vw;
	height: 66vh;
	background: url("../../assets/images/Ellipse\ 1.svg");
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	position: absolute;
	right: 0px;
	bottom: 0;
}

.home-right-img {
	width: 100%;
	height: 400px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	object-fit: contain;
	object-position: right bottom;
}

.home-footer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	transition: all ease-in-out;
	animation: footerIcon 2s infinite;
}

@keyframes footerIcon {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0px);
	}
}

@media only screen and (max-width: 750px) {
	.home-banner img {
		height: 90px;
		width: 100%;
	}

	.home-left {
		width: 95%;
		gap: 20px;
		padding-left: 20px;
	}

	.home-hero-text {
		line-height: 25px;
	}

	.home-right {
		width: 100vw;
		height: 35vh;
	}

	.home-footer {
		display: none;
	}
}
