.gallery {
	min-width: 100%;
	min-height: 100vh;
	height: max-content;
	position: relative;
	display: flex;
	flex-direction: column;
}

.gallery-head {
	display: flex;
	width: 100%;
	height: 100px;
	align-items: flex-start;
	justify-content: center;
}

.gallery-content {
	display: flex;
	padding: 20px;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	min-width: 100%;
	min-height: 100vh;
	height: max-content;
	background: linear-gradient(107.56deg, #fb8340 0%, #fbb68f 100%);
}

.gallery-fullsize img {
	width: 300px;
	height: 300px;
	object-fit: contain;
}

.gallery-fullsize p {
	font-size: 18px;
	color: white;
}