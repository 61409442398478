.about {
	min-width: 100%;
	min-height: 100vh;
	position: relative;
	height: max-content;
	display: flex;
}

.about-blob {
	position: absolute;
	z-index: 10;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 60%;
	background: url("../../assets/images/about_blob.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.about-hero {
	width: 100%;
	height: 100%;
	display: flex;
	background-color: #fdf6b3;
}

.about-left {
	width: 45%;
	height: 100%;
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.about-left img {
	width: 40%;
	height: 50%;
	object-fit: contain;
	z-index: 100;
}

.about-right {
	width: 55%;
	height: 100%;
	padding: 0px 30px;
	display: flex;
	background-color: #fdf6b3;
	z-index: 100;

	flex-direction: column;
}

.about-hero-head {
	width: 100%;
	height: 100px;
	display: flex;
	border-bottom: 2px solid #dadada;
	align-items: center;
	justify-content: flex-end;
}

.about-hero-head img {
	width: 80px;
	height: 80px;
}

.about-hero-text {
	text-align: justify;
}

.about-hero-text p {
	font-size: 18px;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
	.about-blob {
		display: none;
	}

	.about-hero {
		flex-direction: column-reverse;
	}

	.about-left {
		width: 100%;
		height: 35%;
		align-items: flex-end;
	}

	.about-left img {
		width: 170px;
		height: 200px;
	}

	.about-right {
		width: 100%;
		height: 65%;
	}

	.about-right img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.about-hero-head {
		justify-content: flex-end;
	}

	.about-hero-head img {
		width: 80px;
		height: 80px;
	}

	.about-hero-head {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		text-align: right;
	}

	.about-hero-text p {
		font-size: 14px;
	}
}
