.join {
	min-width: 100%;
	min-height: 100vh;
	height: max-content;
	position: relative;
	display: flex;
	flex-direction: column;
}

.join-blob {
	position: absolute;
	z-index: 1;
	top: 0px;
	right: 0px;
	width: 40%;
	height: 50%;
	background: url("../../assets/images/ann_blob.svg");
	background-position: top right;
	background-repeat: no-repeat;
	background-size: contain;
}

.join-hero {
	width: 100%;
	min-height: 80vh;
	display: flex;
	padding-left: 25px;
	background-color: #fdf6b3;
}

.join-left {
	width: 55%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding-bottom: 10px;
}

.join-hero-content {
	display: flex;
	flex-direction: column;
}

.join-content {
	display: flex;
	gap: 30px;
	align-items: center;
	z-index: 110;

}

.join-content img {
	width: 45px;
	height: 45px;
}

.join-text {
	display: flex;
	flex-direction: column;
	line-height: 5px;
}

.join-text p {
	line-height: 5px;
}

.join-content a {
	text-decoration: none;
	color: rgb(44, 114, 180);
	font-weight: bold;
	font-size: 20px;
}

.join-right {
	width: 45%;
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: -100;
	z-index: 100;
}

.join-right img {
	width: 100%;
	height: 400px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	object-fit: contain;
	object-position: right bottom;
}

.join-footer {
	width: 100%;
	height: 100%;
	background-color: brown;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding-bottom: 10px;
}

.footer-text {
	color: white;
	font-size: 20px;
}

.join-footer p {
	color: white;
}

.join-footer-content {
	display: flex;
	width: 70%;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;
}

.join-text p {
	line-height: 30px;
}

.join-footer-content a {
	font-size: 16px;
	color: white;
	text-decoration: none;
}

.join-footer-pcontent {
	font-size: 16px;
	font-weight: bold;
}

@media only screen and (max-width: 600px) {
	.join-blob {
		display: none;
	}

	.join-hero {
		flex-wrap: wrap;
		gap: 50px;
	}

	.join-left {
		width: 100%;
		height: 90vh;
	}

	.join-right {
		width: 100%;
		height: 45%;
	}

	.footer-text {
		font-size: 17px;
		text-align: center;
	}
}
