.announcements {
	min-width: 100%;
	min-height: 100vh;
	height: max-content;
	position: relative;
	display: flex;
}

.ann-blob {
	position: absolute;
	z-index: 10;
	top: 0px;
	right: 0px;
	width: 30%;
	height: 40%;
	background: url("../../assets/images/ann_blob.svg");
	background-position: top right;
	background-repeat: no-repeat;
	background-size: contain;
}

.ann-hero {
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0px 15px;
	background-color: #fdf6b3;
}

.ann-left {
	width: 55%;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.ann-hero-head {
	width: 100%;
	height: 100px;
	display: flex;
	border-bottom: 2px solid #dadada;
	margin-top: 80px;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
}

.ann-hero-head img {
	width: 60px;
	height: 60px;
}

.ann-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.ann-right {
	width: 45%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ann-right img {
	width: 40%;
	height: 100%;
	object-position: center;
	object-fit: contain;
	z-index: 10;
}

@media only screen and (max-width: 600px) {
	.ann-blob {
		display: none;
	}

	.ann-hero {
		flex-wrap: wrap;
		gap: 20px;
	}

	.ann-left {
		width: 100%;
	}

	.ann-right {
		width: 100%;
	}
}
